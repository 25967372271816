<template>
  <CRow class="w-100 justify-content-center">
    <CCard class="w-80">
      <CCardHeader>
        <div class="card-header-action mt-2 ml-2">
          <h2>Деталі поданої пропозиції</h2>
          <p class="mb-0">ID тендеру: <span class="font-weight-bold">{{ offers.order_id }}</span></p>
          <p v-if="!order"><span class="font-weight-bold">{{ order?.branch_a }}</span>
            {{ order?.branch_a_address }}</p>

          <CAlert v-if="message.show" style="font-size: 12px;" show color="danger">{{ message.failed_message }}</CAlert>
        </div>
      </CCardHeader>
      <CCardBody>
        <CCard class="p-5">
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-4 font-weight-bold">ID тендеру
              <btn-copy class="ml-1 p-0 px-2" :value="orderId"/>
            </p>
            <p class="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8">{{ offers.order_id }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Вартість підключення, грн з ПДВ</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.connection_cost }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Щомісячний платіж, грн з ПДВ</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.monthly_payment }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Швидкість на прийом, Мбіт/с <br>(Європа)</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.download_speed }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Швидкість на віддачу, Мбіт/с <br>(Європа)</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.upload_speed }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Тип каналу</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.type_channel }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Фізика</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{
                $store.getters.getPhysicsByID(offers.physics_id)
              }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Інтерфейс</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              {{ $store.getters.getInterfaceByID(offers.iface_id) }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Протокол</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              {{ $store.getters.getProtocolByID(offers.protocol_id) }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Потрібно обладнання?</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.is_equipment ? "Так" : "Ні" }}</p>
          </div>
          <div class="row border-bottom mb-2" v-if="offers.is_equipment">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Впишіть обладнання</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <ul style="list-style-type: none" class="p-0">
                <li v-for="extra in offers.extra.split(',')" :key="extra">
                  {{ (extra) }}
                </li>
              </ul>
            </p>
          </div>
          <div class="row border-bottom mb-2" v-if="offers.is_equipment">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Чиє обладнання?</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.whose_equipment }}</p>
          </div>
          <div class="row border-bottom mb-2" v-if="offers.whose_equipment !== 'Банк'">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Умови передачі обладнання?</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.equipment_transfer }}</p>
          </div>
          <div class="row border-bottom mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Необхідний час для <br/>організації
              каналу (Роб.днів)</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.days_to_connect }}</p>
          </div>
          <div class="row mb-2">
            <p class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 font-weight-bold">Примітка</p>
            <p class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">{{ offers.note }}</p>
          </div>


          <template v-if="can('bank_user')">
            <hr/>
            <CCardHeader class="mt-3 mb-3 pl-1 pb-2">
              <h4>Провайдер</h4>
            </CCardHeader>

            <div class="row border-bottom mb-2" v-if="offers.name">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Ім'я Організації</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.name }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.brand">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Бренд</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.brand }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.ipn">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">ІПН</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.ipn }}</p>
            </div>
            <div class="row border-bottom mb-2" v-else>
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">ЕРДПОУ</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.edr }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.website">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Web-site</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.website }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.accounting_contacts">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Телефон <br>абон.відділу/бухгалтерії
              </p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                <ul style="list-style-type: none" class="p-0">
                  <li v-for="tel in offers.accounting_contacts.split(',')" :key="tel">
                    <a :href="'tel://'+tel">
                      {{ tel }}
                    </a>
                  </li>
                </ul>
              </p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.accounting_emails">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Email абон.відділу/бухгалтерії</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.accounting_emails }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.technical_support_contacts">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Телефон Тех.підтримки</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                <ul style="list-style-type: none" class="p-0">
                  <li v-for="tel in offers.technical_support_contacts.split(',')" :key="tel">
                    <a :href="'tel://'+tel">
                      {{ tel }}
                    </a>
                  </li>
                </ul>
              </p>
            </div>
            <div class="row border-bottom mb-2" v-if="offers.technical_emails">
              <p class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-8 font-weight-bold">Email Тех.підтримки</p>
              <p class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">{{ offers.technical_emails }}</p>
            </div>
            <CCardHeader class="border-bottom-0 pl-0 pt-lg-3">
              <h4>Користувачі</h4>
            </CCardHeader>
            <CCardBody
                v-if="offers.provider_users !== 0"
                class="p-0 m-0">
              <CDataTable
                  hover
                  striped
                  :items="offers.provider_users"
                  :fields="fieldsUsers"
                  :items-per-page="20"
                  :pagination="$options.paginationProps"
                  index-column
                  clickable-rows
              >
                <template #email="data">
                  <td>
                    {{ data.item.email }}
                  </td>
                </template>
                <template #name="data">
                  <td>
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </td>
                </template>
                <template #phones="data">
                  <td>
                    <ul style="list-style-type: none; width: 180px" class="p-0">
                      <li v-for="tel in data.item.phones.split(',')" :key="tel">
                        <a :href="'tel://'+tel">
                          {{ tel }}
                        </a>
                      </li>
                    </ul>
                  </td>
                </template>
                <template #notification="data">
                  <td>
                    <CBadge class="p-1" :color="getBadge(data.item.notification)">
                      {{ data.item.notification ? "Включено" : "Вимкнено" }}
                    </CBadge>
                  </td>
                </template>
                <template #role="data">
                  <td>
                    <CBadge class="p-1" :color="getBadgeRole(data.item.role)">
                      {{ data.item.role }}
                    </CBadge>
                  </td>
                </template>

              </CDataTable>
            </CCardBody>
          </template>
        </CCard>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="mb-1">
            <CButton
                color="secondary"
                class="px-4"
                @click="goBack"
            >
              Назад
            </CButton>
          </CCol>
          <CCol col="12" sm="9" md="9" lg="9" xl="9" class="text-right">
            <template v-if="!is_admin_hide">
              <CButton
                  v-if="offers.order_status === 'OpenOrder'"
                  color="primary"
                  class="px-3 mb-1"
                  @click="editOffer(offers.id)"
              >
                Внести зміни
              </CButton>
              <CButton
                  v-if="offers.order_status  === 'OpenOrder'"
                  color="danger"
                  class="px-3 ml-3 mb-1"
                  @click="removeOffers"
              >
                Відмінити пропозицію
              </CButton>
            </template>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </CRow>
</template>

<script>
import axios from 'axios'
import BtnCopy from "../Components/Button/BtnCopy.vue";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
  name: "TenderDetails",
  components: {BtnCopy},
  data: () => {
    return {
      order: null,
      is_admin_hide: false,
      is_file: false,
      massage: {
        noFile: "Прикріпити файл:",
        isFile: "Файл надіслано",
      },
      offers: {},

      fieldsUsers: [
        {key: 'email', label: 'Емейл'},
        {key: 'name', label: "Ім'я"},
        {key: 'phones', label: 'Контактні телефони'},
      ],
      providers: {},
      physics: {},
      interfaces: [],
      protocol: [],
      message: {
        show: false,
        failed_message: "Не видалено, спробуйте пізніще",
      },
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    can,

    getBadgeRole(role) {
      return role === 'Адмін' ? 'info'
          : role === 'Користувач' ? 'warning' : 'primary'
    },

    getBadge(notification) {
      return notification === true ? 'success' : notification === false ? 'secondary' : 'primary';
    },

    uploadFile() {
      let self = this;
      let formData = new FormData();
      formData.append('file', this.file);
      //console.log('>> formData >> ', formData);

      // You should have a server side REST API
      axios.post('/api/offers/' + self.offers.id + '/file',
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function () {
        //console.log('SUCCESS!!');
      })
          .catch(function () {
            //console.log('FAILURE!!');
          });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      //console.log('>>>> 1st element in files array >>>> ', this.file);
      this.uploadFile();
    },

    goBack(goTenders) {
      if (goTenders && can('provider_user')) {
        return routerGoToName('TendersViewOffers', 'params', {id: this.offers.order_id.toString()});
      }
      if (can('provider_user')) {
        return routerGoToName('TendersViewOffers', 'params', {id: this.offers.order_id.toString()});
      }
      if (can('bank_user')) {
        return routerGoToName('TendersViewProvidersOffer', 'params', {id: this.offers.order_id.toString()});
      }
    },

    removeOffers() {
      if (!confirm("Ви справді бажаєте видалити пропозицію?")) {
        return;
      }
      let self = this;
      axios.delete('/api/offers/' + this.offers.id)
          .then(function (offers_status) {
            if (offers_status.status === 204) {
              self.goBack(true);
            }
          })
          .catch(function () {
            self.message.show = true;
            self.$router.go(-1);
          })
    },

    getOrderByID(order_id) {
      let self = this;
      let url = ""
      if (can('bank_user')) {
        url = `/api/orders/${order_id}`
      } else {
        url = `/api/account/orders/${order_id}`
      }
      axios.get(url).then(function (response) {
        self.order = response.data;

      }).catch(function () {
      });
    },

    getOffersDetails() {
      let self = this;
      axios.get('/api/account/offers/' + self.$route.params.offers_id)
          .then(function (response) {
            self.$set(self, "offers", response.data);

            self.getOrderByID(self.offers.order_id);
          })
          .catch()
    },

    editOffer(id) {
      routerGoToName('TendersOfferEdit', 'params', {id: id.toString()});
    },

    getOffersById(id) {
      let self = this;
      axios.get('/api/offers/' + id.toString())
          .then(function (response) {
            self.$set(self, "offers", response.data);

            self.getOrderByID(self.offers.order_id);
          })
          .catch()
    },

    getOffersFile() {
      let self = this;
      axios.get('/api/offers/' + self.offers.id + '/file')
          .then(function (response) {
            if (response.status === 404) {
              this.is_file = false;
            }
            if (response.status === 204 || response.status === 200) {
              this.is_file = true;
            }
          })
          .catch(function (error) {
            if (error.status === 404) {
              this.is_file = false;
            }
          })
    },

    funcShowOffers() {
      this.$store.dispatch('GET_ORGANIZATION');

      if (this.$store.state.organization.id !== 0 && can('provider_user')) {
        this.getOffersDetails();
        this.is_admin_hide = false;
        return;
      }

      if (this.offers.status === "Winner") {
        this.getOffersFile();
      }

      // if (can('bank_user') && !('vd' in this.$store.state.login_info.roles)) {
      if (can('bank_user')) {
        if (this.$route.params.offers_id) {
          this.is_admin_hide = true;
          this.getOffersById(this.$route.params.offers_id);
          return;
        }
      }
    }
  },
  computed: {
    orderId() {
      return this.offers && this.offers.order_id ? this.offers?.order_id.toString() ?? '' : '';
    }
  },
  created() {
    if (!this.$store.getters.getPhysicsLength) this.$store.dispatch('GET_Physics');
    if (!this.$store.getters.getInterfaceLength) this.$store.dispatch('GET_Interface');
    if (!this.$store.getters.getProtocolLength) this.$store.dispatch('GET_Protocol');

    if (this.$store.state.login_info.ready) {
      this.funcShowOffers()
    }

  },
  watch: {
    '$store.state.login_info.ready': function () {
      return this.funcShowOffers();
    },
    '$store.state.organization.id': function () {
      return this.funcShowOffers();
    }
  }
}
</script>